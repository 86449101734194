.Image_Fit {
  object-fit: cover;
  // object-position: center;
}

// card border style
.card-brd {
  border-radius: 8px;
  border: 1px solid rgba(73, 80, 87, 0.2);
  // object-fit: contain !important;
}

.bg-color-tab {
  background-color: rgba(73, 80, 87, 0.15);
}
/******************
* Register as page 
*******************/

.customize---topBar {
  // background-color: #f3f3f4 !important;
  background-color: #fff !important;
  border-bottom: 1px solid #f5f5f5;
  box-shadow: 2px 0 5px 5px rgba(0, 0, 0, 0.2);
}

.Specification__Card__picture__img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

/******************
* my events
*******************/
//become host
.btnMyEventHostEvent {
  border-radius: 10px;
  background-color: $primary !important;
  // color: $white;
}
.btnMyEventHostEvent:hover {
  background-color: $black !important;
}
// create event
.btnMyEventCreateEvent {
  border-radius: 10px;
  background-color: $black !important;
  // color: $white;
}
.btnMyEventCreateEvent:hover {
  background-color: rgb(146, 145, 145) !important;
}

// myevent filter buton
.btnMyEventFilterBtn1 {
  border-radius: 20px;
  background-color: #626c76;
  color: #ffff;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.btnMyEventFilterBtn1:hover {
  border-radius: 20px;
  background-color: white;
  color: #626c76;
  border: 2px solid #626c76;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.btnMyEventFilterBtn2 {
  border-radius: 20px;
  background-color: white;
  color: #626c76;
  border: 2px solid #626c76;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.btnMyEventFilterBtn2:hover {
  border-radius: 20px;
  background-color: #626c76;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.dashboard-heading-container-card {
  width: 100%;
  display: flex;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 10px;
  margin-bottom: 10px;
  .dashboard-heading-event-name {
    display: flex;
    align-items: center;
    width: 57%;
  }
}

// view all events
.dashboard-container-card {
  width: 100%;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid rgb(226, 226, 226);
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  .dashboard-container-img-card {
    width: 14.75rem;
    height: 10.1rem;
    position: relative;
    img {
      position: absolute;
      width: 14.75rem;
      height: 10.1rem;
      border-radius: 10px;
      // object-fit: contain;
    }
  }
  .dashboard-wrapper {
    border: 3px solid green;
    width: 56%;
    display: flex;
    .dashboard-event-content {
      width: 56%;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      border: 3px solid red;
      h3 {
        flex-wrap: wrap;
      }
      // background-color: red;
    }
  }

  .dashboard-event-status {
    padding: 10px;
    width: 18%;
    background-color: blue;
  }
  .dashboard-event-action {
    padding: 10px;
    width: 18%;
    background-color: green;
  }
}
.MyEvent_Parent_div {
  position: relative;
  width: 14.75rem;
  height: 10.1rem;
  // background-color: red;
  // height: 17em;
}
.MyEvent_image_div {
  width: 14.75rem;
  height: 10.1rem;
  object-fit: contain;
  // display: flex;
  // justify-content: center !important;
  // align-items: center ;
  // overflow: hidden;
  img {
    border-radius: 10px;
  }
}

.MyEvent_banner_div {
  position: absolute;
  top: 1px;
  right: 0px;
}
.MyEvent__ribbon__text {
  font-size: 14px;
}
.MyEvent__text {
  font-size: 18px;
  font-weight: bold;
}
.MyEvent__date {
  font-size: 14x;
}
.MyEvent__status {
  font-size: 18px;
}

// .btnMyEventEdit {
//     display: inline;
//     text-align: center;
//   border-radius: 20px;
//   background-color: green !important;
//   color: white !important;
//     border: none;
//     font-size: 12px;
//     padding-left: 5px;
//     padding-right: 5px;
//     padding-top: 10px;
//     padding-bottom: 10px;
// }

.btnMyEventEdit {
  border-radius: 20px !important;
  background-color: green !important;
  color: white !important;
}
.btnMyEventEdit:hover {
  border-radius: 20px;
  background-color: black !important;
  color: white !important;
}

// preview event
.btnPublish {
  border-radius: 20px !important;
  background-color: $showbay-bg !important;
}

.btnPublish:hover {
  border-radius: 20px;
  background-color: black !important;
  color: white !important;
}

// My event banner ribbon conference styles
.ribbon-2 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  left: 10px;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #1a87a3;
  width: 8em;

  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

// My event banner ribbon exhibitor styles
.ribbon-3 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: rgb(163, 38, 38);
  width: 8em;

  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

// My event banner ribbon social styles
.ribbon-4 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: rgb(37, 182, 37);
  width: 8em;

  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

// My event banner ribbon expo styles
.ribbon-5 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: rgb(255, 215, 0);
  width: 8em;

  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

/****************************************
* Create Events
*****************************************/

//we will see this after
.Specify__Card__container {
  display: flex;
  // flex-wrap: wrap;
}
.Specify__Card {
  width: 248px;
  justify-content: center;
  border-radius: 10px;
  background-color: white;
}
.Specify__Card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25) !important;
}
.Specify__Card__img {
  // width: 100%;
  // height: 100%;
  width: 248px;
  height: 140px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

// to rebuild this below code

.event__form__banner_container {
  position: relative;
}

.event__form__banner_img {
  width: 850px;
  height: 350px;
  border: 2px solid #1a87a3 !important;
  border-radius: 10px;
}

.btnUploadEventBanner {
  position: absolute;
  top: 40%;
  bottom: 40%;
  left: 40%;
  right: 40%;
  background-color: transparent !important;
}
.form-create-event {
  width: 850px;
}
.event__input__form__Creation {
  width: 100%;
  max-width: 100%;
}

.section---banner---container {
  position: relative;
  margin-right: 2% !important;
  border-radius: 10px;
  border: 1px dashed #626c76;
}
.section---banner---container---img {
  border-radius: 10px;
}

.profile-container-wrapper-left {
  display: none;
}
.profile-container {
  background-color: "white" !important;
  border-radius: 10px;
  box-shadow: 0px 0px 99px 0px rgba(0, 0, 0, 0.1);
}

.profile-container-left {
  position: fixed !important;
  width: 20%;
  // top: 0;
  background-color: "white" !important;
  border-radius: 10px;
  box-shadow: 0px 0px 99px 0px rgba(0, 0, 0, 0.1);
  .profile-container-left-option-1 {
    padding: 4px 0px 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  .profile-container-left-option-1:hover {
    background-color: rgb(221, 221, 221) !important;
    color: white;
  }
  .profile-container-left-option-2 {
    padding: 4px 0px 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    // margin
  }
  .profile-container-left-option-2:hover {
    background-color: rgb(221, 221, 221) !important;
    color: white;
  }
  .profile-container-left-option-3 {
    padding: 4px 0px 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    // margin
  }
  .profile-container-left-option-3:hover {
    background-color: rgb(221, 221, 221) !important;
    color: white;
  }
  .profile-container-left-option-4 {
    padding: 4px 0px 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    // margin
  }
  .profile-container-left-option-4:hover {
    background-color: rgb(221, 221, 221) !important;
    color: white;
  }
}

.profile-container-Title {
  font-size: 20px;
  font-weight: 700;
}

.profile-container-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: #bfc4c9;
}

.profile-img-container {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.1);
  background-color: #1a87a3;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}
.profile-container-main {
  width: 90%;
}

.profile-form-title {
  font-size: 20px;
  font-weight: 700;
}
// .event__form__banner {
//   width: 900px;
//   height: 400px;
//   border-radius: 10px;
//   border-color: #0005;
// }

// .image__Preview__Container__event__Form_Picture__event__conference {
//   border-radius: $card-brd;
//   object-fit: cover;
//   border: 3px solid #1a87a3;
// }

// .image__Preview__Container__event__Form_Picture__event__Exhibition {
//   border-radius: $card-brd;
//   object-fit: cover;
//   border: 3px solid #a32626;
// }
// .image__Preview__Container__event__Form_Picture__event__Social {
//   border-radius: $card-brd;
//   object-fit: cover;
//   border: 3px solid #25b625;
// }

//booth styling
.event--booth--contanier {
  margin-top: 1.2em;
  // background-color: #1a87a3;
}
