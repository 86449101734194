.booth___body {
  width: 100%;
  height: 100%;

  .booth___img {
    width: 55%;
    height: 20em;
  }
  .booth___extra {
    width: 45%;
  }
}

.booth-body {
  height: 600px;
  width: 100%;
  overflow-y: auto;
  //   background-color: brown;

  display: flex;
  //   gap: 8px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.booth-body__details {
  //   background-color: orangered;
  width: 55%;
  height: 100%;
  margin-right: 8px;
}

.booth-body__chat {
  //   background-color: purple;
  width: 45%;
  height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;

  .download-session-documents {
    background-color: #2dd173;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
    margin: 16px auto;
  }

  .notify-on-live {
    &--description {
      color: #afacbb;
      font-size: 13px;
    }
    &--button {
      background-color: #2dd173;
      color: #ffffff;
      border: none;
      border-radius: 8px;
      padding: 8px 12px;
      margin: 8px auto;
    }
  }
}
