.activities---container {
  border: 2px solid #a7a7a7bd;
  border-radius: 10px;
}
.verticalLine {
  border-left: 1px solid #a7a7a7bd;
  height: 70px;
  margin: 0px 5px 0px 5px;
}

.activity---time--container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 120px;
}

.activity--btn-edit {
  width: 100px;
  height: 40px;
}

// .activity---time {
//   position: absolute;
//   float: top;
//   margin-top: 0;
// }

// .activity---location {
//   position: absolute;
//   float: bottom;
// }
.sessions-list__heading {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: 700;
    margin-bottom: 0;
  }
}

.sessions-list__body {
  height: 480px;
  overflow-y: auto;
  background-color: #fafafabd;
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.382));
  border: 1px solid #1d1c22;
  border-radius: 12px;
  // color: #fefefe;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  padding: 16px 0 0 32px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.sessions-list__date {
  margin: 40px 0;
  // background-color: #297ea6;
  background-color: #45cb85;
  padding: 12px 24px;
  border-radius: 12px;
  width: max-content;

  h4 {
    margin-bottom: 0;
    // color: white;

    span {
      font-size: 12px;
    }
  }
}

.sessions-list__items {
  &__item {
    margin: 24px 0;
    border-bottom: 1px dashed #1d1c22;

    &--time {
      font-size: 18px;
      letter-spacing: 1px;
    }
    &--name {
      margin-left: 24%;

      &:hover {
        background-color: #1d1c22;
        border: 1px solid #fefefe;
        color: #fefefe;
        border-radius: 12px;
        padding: 16px 24px;
        cursor: pointer;
      }
    }
  }
}

.sessions-content--summary {
  max-width: 70%;
  flex: 4;
  background-color: #fefefe;
  padding: 32px 72px;
  box-sizing: border-box;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
