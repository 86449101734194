.sponsor-form-container {
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
  height: 650px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.sponsor-form-body {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  padding-right: 8px;

  &__sponsor-logo {
    display: flex;
    padding-bottom: 32px;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;

    &__upload {
      border-radius: 16px;
      padding: 86px 68px;
      -webkit-box-flex: 1;
      flex-grow: 1;
      -webkit-box-align: center;
      -webkit-box-pack: center;
      border: 1px dashed rgb(86, 83, 101);
      text-align: center;
      cursor: pointer;
      background-color: rgba(86, 83, 101, 0.1) !important;

      .placeholder-text {
        color: rgb(7, 7, 8);
        display: flex;
        align-items: center;
        flex-direction: column;

        .cws {
          display: flex;
        }
      }
    }
  }

  &__input {
    margin: 0px 0px 12px;

    &--field {
      padding: 16px;
      border-radius: 8px;
      color: rgb(26, 25, 31);
      transition: all 300ms ease 0s;
      border: 1px solid rgb(143, 140, 161);
      background-color: transparent;
      width: 100%;
      font-size: 1em;

      &:hover {
        border: 1px solid #45cb85;
      }
    }
  }

  &--input-heading {
    margin-bottom: 4px;
    line-height: 16px;
    font-size: 13.5px;
    overflow-wrap: break-word;
    color: rgb(17, 16, 20);
  }

  &--input-description {
    padding-top: 8px;
    line-height: 16px;
    font-size: 12px;
    overflow-wrap: break-word;
    color: rgb(64, 62, 76);
  }
}

.btn-add-sponsor {
  width: 100%;
  color: rgb(7, 7, 8);
  -webkit-box-align: center;
  align-items: center;
  padding: 14px 5px;
  font-style: normal;
  font-size: 16px;
  line-height: 125%;
  height: 50px;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease 0s;
  margin-top: 20px;
  cursor: pointer;
  position: relative;
  background: #45cb85;
  color: #fefefe;
}

.field-col-half {
  width: calc(50% - 8px);
  float: left;

  &:first-child {
    margin-right: 16px;
  }
}

.gzIdbH {
  margin-bottom: 16px;
  position: relative;
  border: 1px solid transparent;
  border-radius: 8px;
  background: transparent;
}

p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

input {
  overflow: visible;
}

// input[type="file"] {
//   opacity: 0;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   z-index: 100;
//   width: 100%;
//   cursor: pointer;
// }

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
