.success-message {
    background: #ffffff;
    color: #1DA176;
    font-size: 18px;
    width: 20vw;
    padding: 0.4em;
    font-family: 'Times New Roman', Times, serif;
}

.warning-message {
    background: #ffffff;
    color: #f1b44c;
    font-size: 18px;
    width: 28vw;
    padding: 0.8em;
    font-family: 'Times New Roman', Times, serif;
}

.danger-message {
    background: #ffffff;
    color: #E54657;
    font-size: 18px;
    width: 28vw;
    padding: 0.8em;
    font-family: 'Times New Roman', Times, serif;
}

.notification__belt {
    position: relative;
}

.notification__belt__number {
    position: absolute;
    top: 0;
}