.booth-body {
  height: 600px;
  width: 65%;
  overflow-y: auto;
  //   background-color: brown;

  display: flex;
  //   gap: 8px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.booth-body__details {
  //   background-color: orangered;

  width: 55%;
  height: 100%;
  margin-right: 8px;
}

.booth-body__chat {
  //   background-color: purple;
  width: 100%;
  height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
}
