.ticketing-content {
  width: 100%;
}
.ticketing-placeholder-container {
  height: 100vh;
  display: flex;
  .ticketing-placeholder-img {
    width: 450px;
    height: 350px;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.registration-content__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.registration-content__body {
  height: 91.9%;
  position: relative;
  text-align: center;

  &--no-tickets {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%);
  }
}

.registration-content__faded {
  color: rgba(35, 38, 51, 0.6);
  // width: 55% !important;
}

.tickets-all-btn {
  width: 100%;

  button {
    width: 150px;
    height: 40px;
  }
}

.tickets-card {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: white;

  box-shadow: 0 1px 5px 0 rgba(202, 202, 202, 0.2),
    0 6px 5px 0 rgba(218, 218, 218, 0.19);
}

.discount {
  font-size: 20px;
}
.discount-text {
  font-size: 18px;
  font-weight: "700";
}
.discount-date {
  font-size: 18px;
  font-weight: "700";
}
