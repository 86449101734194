.slide-in-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10010;
}

.slide-in-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  // z-index: 1005;
}

.slide-in-content {
  position: absolute;
  top: 0;
  right: -100%;
  width: 30%; /* Adjust width as needed */
  height: 100%;
  background-color: #e4e5e6;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  // z-index: 999;
  padding: 32px;
  color: #495057;
}

.slide-in-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: 600;
  }
}

.slide-in-body {
  margin-top: 12px;
  height: 100%;
}

.slide-in-modal.show .slide-in-content {
  right: 0;
}

.slide-in-modal.show {
  display: block;
}

.registration-menu__link--active {
  padding-bottom: 16px;
  border-bottom: 4px solid #45cb85 !important; /* Adjust color and thickness as needed */
}
