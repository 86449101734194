.live-component .btn-wave::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 140px;
  height: 60px;
  border-radius: 14px;
  background: rgba(0, 123, 255, 0.6);
  z-index: -1;
  animation: wave 2s infinite ease-out;
}
@keyframes wave {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
