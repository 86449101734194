.main {
  width: 100%;
  position: relative;
  height: 450px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .wrapper {
    position: absolute;
    width: 100%;
    height: 450px;
    display: flex;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(8px);
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0);
    .wrapper-left {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .wrapper-left-Title {
        width: 75%;
        flex-wrap: wrap;
      }
      p {
        font-size: 35px;
        font-weight: 600;
        color: white;
      }
      .wrapper-left-subTitle {
        display: flex;
      }
      .wrapper-left-btn {
        padding: 4px 6px 4px 6px;
        border: 1px solid white;
        color: white;
        border-radius: 5px;
        font-size: large;
        font-weight: 100;
      }
      .wrapper-left-line {
        color: white;
        width: 350px;
      }
    }
    .wrapper-right {
      width: 60%;
      height: 450px;
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: blur(0);
      }
    }
  }
}

//event Time

.social--event--Location {
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
  background-color: white;

  .date--separator {
    height: 100%;
    border-left: 1px solid rgb(216, 216, 216);
    margin: 0px 15px 0px 15px;
  }

  .btn--social--buy {
    background-color: black !important;
    border-radius: 8px;
    color: white;
    font-size: larger;
    width: 280px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .btn--social--enter {
    background-color: #1a87a3 !important;
    border-radius: 8px;
    color: white;
    font-size: larger;
    width: 280px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.social--event--Details {
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
  // padding-right: 15px;
  // padding-bottom: 3%;
}
.social--event--Information {
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
  background-color: white;
}
.social--event--Organizer {
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
  // background-color: white;
}

.social--section--title {
  font-size: 28px;
}
.underline---social {
  width: 20px;
  border: 2px solid rgb(63, 63, 63);
}

.social--event--map {
  height: 300px;
}
