.circle {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #21929c; /* Dark circle color */
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
}

.withdrawal-list-description-container {
  margin: 32px auto;
}

.withdrawal-table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd; /* Outside border */
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  background-color: white;
  margin-top: 5px;
}

.withdrawal-table__head--cell,
.withdrawal-table--data {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Border between rows */
}

.withdrawal-table__head--cell {
  background-color: #f2f2f2;
}

.withdrawal-table__head--cell:first-child,
.withdrawal-table--data:first-child {
  border-left: 1px solid #ddd; /* Border between columns */
  border-radius: 10px 0 0 0;
}

.withdrawal-table__head--cell:last-child,
.withdrawal-table--data:last-child {
  border-right: 1px solid #ddd; /* Border between columns */
  border-radius: 0 10px 0 0;
}

.withdrawal-table__row:nth-child(even) {
  background-color: #f9f9f9;
}

.withdrawal-table__row:nth-child(odd) {
  background-color: #ffffff;
}
