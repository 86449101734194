.slide-in-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
}

.slide-in-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.slide-in-content {
  position: absolute;
  top: 0;
  right: -100%;
  width: 30%; /* Adjust width as needed */
  height: 100%;
  background-color: #e4e5e6;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;

  padding: 32px;
  color: #495057;
}

.slide-in-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-top: 8px;
    color: rgb(83, 80, 98);
    line-height: 16px;
    font-size: 13px;
    font-weight: 400;
    font-weight: normal;
  }
}

.slide-in-body-attendee {
  margin-top: 12px;
  height: 650px;
  overflow-y: auto;

  &__links {
    display: flex;
    justify-content: space-between;
    color: rgb(93, 90, 109);
    font-weight: normal;
    line-height: 18px;
    font-size: 14px;

    a {
      color: rgb(93, 90, 109);
      padding-bottom: 16px;
      border-bottom: 1px solid rgb(93, 90, 109);

      &:first-child {
        margin-left: 24px;
      }

      &:last-child {
        margin-right: 24px;
      }
    }
  }

  &__content {
    margin-top: 32px;
    width: 100%;
    height: 100%;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.slide-in-modal.show .slide-in-content {
  right: 0;
}

.slide-in-modal.show {
  display: block;
}

.registration-menu__link--active {
  padding-bottom: 16px;
  border-bottom: 4px solid #45cb85 !important; /* Adjust color and thickness as needed */
}
