.people-attendees__heading {
  margin-bottom: 32px;

  h3 {
    font-weight: 700;
    margin-bottom: 0;
  }
}

.people-attendees__body {
  height: 100%;

  &--heading {
    h5 {
      font-weight: 700;
      width: max-content;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 32px;
        margin-top: 6px;
        background-color: #3b5de7;
      }
    }
  }

  &--content {
    height: 80%;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .no-attendees {
      margin-bottom: 16px;
    }

    h3 {
      font-weight: 700;
      letter-spacing: -0.2px;
    }

    p {
      font-size: 16px;
      width: 50%;
    }
  }
}

.circle {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #21929c; /* Dark circle color */
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
}

.attendees-list-description-container {
  margin: 32px auto;
}

.attendees-table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd; /* Outside border */
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  background-color: white;
  margin-top: -32px;
}

.attendees-table__head--cell,
.attendees-table--data {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Border between rows */
}

.attendees-table__head--cell {
  background-color: #f2f2f2;
}

.attendees-table__head--cell:first-child,
.attendees-table--data:first-child {
  border-left: 1px solid #ddd; /* Border between columns */
  border-radius: 10px 0 0 0;
}

.attendees-table__head--cell:last-child,
.attendees-table--data:last-child {
  border-right: 1px solid #ddd; /* Border between columns */
  border-radius: 0 10px 0 0;
}

.attendees-table__row:nth-child(even) {
  background-color: #f9f9f9;
}

.attendees-table__row:nth-child(odd) {
  background-color: #ffffff;
}

.sponsors---img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
