.booth__body {
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
  height: 550px;
  overflow-y: auto;

  &--form {
    .input-heading {
      margin-bottom: 6px;
      line-height: 16px;
      font-size: 14px;
      overflow-wrap: break-word;
      color: rgb(7, 7, 8);
    }

    .input-description {
      line-height: 12px;
      font-size: 11px;
      overflow-wrap: break-word;
      color: rgb(64, 62, 76);
    }

    &-logo-container {
      margin-bottom: 24px;
      margin-top: 4px;

      &__uploader-container {
        margin-bottom: 16px;
        margin-top: 16px;
      }

      &__uploader {
        padding: 0px 11px;
        height: 90px;
        width: 90px;
        border-radius: 12px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        background: rgb(211, 210, 214) !important;

        .placeholder-text {
          width: 100%;

          .logo-btn {
            -webkit-box-pack: center;
            justify-content: center;
            display: flex;
          }
        }

        // input[type="file"] {
        //   position: absolute;
        //   inset: 0px;
        //   width: 100%;
        //   opacity: 0;
        //   cursor: pointer;
        //   z-index: 1;
        // }
      }
    }

    &-input-container {
      margin-bottom: 24px;

      &__label {
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      & > div > div:nth-child(3) {
        clear: left;
      }
    }

    &__features {
      margin: 32px auto;

      &--options {
        margin: 16px auto;

        &-option {
          // display: flex;
          // justify-content: space-between;

          &-label {
            width: 95%;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 8px;

            &__toggle-container {
              -webkit-box-align: center;
              align-items: center;
              display: flex;
              margin-left: 32px;

              label {
                margin: 0px;
                display: flex;
                flex-direction: row;
                -webkit-box-pack: start;
                justify-content: flex-start;
                -webkit-box-align: center;
                align-items: center;
                cursor: pointer;

                .booth__body__features--options-option-label__toggle {
                  margin-left: 10px;
                  cursor: pointer;
                  opacity: 1;
                  background-color: #1a87a3;
                  width: 44px;
                  height: 24px;
                  border-radius: 46px;
                  border: 1px solid rgb(187, 185, 198);

                  &--switch {
                    background-color: rgb(252, 252, 252);
                    top: 2px;
                    bottom: 2px;
                    width: 18px;
                    height: 18px;
                    margin-left: auto;
                    left: 32.5px;
                  }
                }
              }
            }
          }

          &-description {
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.field-col-half {
  width: calc(50% - 8px);
  float: left;

  &:first-child {
    margin-right: 16px;
  }
}

.gzIdbH {
  margin-bottom: 16px;
  position: relative;
  border: 1px solid transparent;
  border-radius: 8px;
  background: transparent;
}

input {
  overflow: visible;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

label {
  display: inline-block;
}
