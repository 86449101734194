.people-container {
    background-color: rgba(73, 80, 87, 0.15);
    color: #232633;
    // width: 100%;
    height: 100vh;
    padding: 24px 80px;
  
    display: flex;
  }
  
  .people-menu {
    flex: 1;
    width: 20%;
    background-color: #f0f0f0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 24px 0;
  
    &__item {
      list-style-type: none;
      margin: 16px auto;
  
      &__link {
        color: rgba(35, 38, 51, 0.6);
        font-size: 14px;
        letter-spacing: -0.2px;
      }
  
      &__item--active {
        border-left: 4px solid #3b5de7;
      }
    }
  }
  
  .people-content {
    flex: 4;
    background-color: #fefefe;
    padding: 32px 72px;
    box-sizing: border-box;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  