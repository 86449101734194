// .btn---ticket--left {
//   border-top-left-radius: 15px !important;
//   border-top-right-radius: 15px !important;
// }

.form-create-ticket {
  width: 780px !important;
  display: flex;
  justify-content: center;
  //   align-items: center;
  //   background-color: aqua !important;
}
