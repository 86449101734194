.stepperContainer {
  margin-top: 30px;
  width: 700px;
  align-self: center;
  border-radius: 10px;
  background-color: #fff;
}

.stepperHeader {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  // margin-top: 30px;
  // width: 700px;
  // align-self: center;
  // background-color: #28a745;
}

.stepper-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.step {
  flex: 1;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 50%; /* Make it round */
  /*margin: 0 5px; Add some space between the indicators */
}

.step.active {
  background-color: #1a87a3 !important;
  color: #fff;
}

.step.completed {
  background-color: #28a745;
  color: #fff;
}
