.sponsors-list__heading {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: 700;
    margin-bottom: 0;
  }
}

.sponsors-list__body {
  &__heading {
    border-bottom: 2px solid #232633;
    padding-bottom: 16px;

    .tier {
      border-radius: 8px;
      display: flex;
      padding: 20px;
      margin: 5px 8px;
      background-color: rgba(240, 240, 240, 0.8);
      border: 1px solid rgba(240, 240, 240, 1);

      &--position {
        overflow: hidden;
        overflow-wrap: normal;
      }
      &--rank {
        padding-left: 18px;
        flex: 1 1 0%;
      }
    }
  }
}

.sponsors {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd; /* Outside border */
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  background-color: white;
  margin-top: 40px;
}

.sponsors__head--cell,
.sponsors--data {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Border between rows */
}

.sponsors__head--cell {
  background-color: #f2f2f2;
}

.sponsors__head--cell:first-child,
.sponsors--data:first-child {
  border-left: 1px solid #ddd; /* Border between columns */
  border-radius: 10px 0 0 0;
}

.sponsors__head--cell:last-child,
.sponsors--data:last-child {
  border-right: 1px solid #ddd; /* Border between columns */
  border-radius: 0 10px 0 0;
}

.sponsors__row:nth-child(even) {
  background-color: #f9f9f9;
}

.sponsors__row:nth-child(odd) {
  background-color: #ffffff;
}
