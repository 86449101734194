.issued--invoice--container {
  border-right: 2px solid rgb(126, 126, 126);
  border-top: 2px solid rgb(126, 126, 126);
  padding: 5px;
}
.billed--invoice--container {
  border-right: 2px solid rgb(126, 126, 126);
  border-top: 2px solid rgb(126, 126, 126);
  padding: 5px;
}
.from--invoice--container {
  border-right: 2px solid rgb(126, 126, 126);
  border-top: 2px solid rgb(126, 126, 126);
  padding: 5px;
}
.table--header--invoice--container {
  border-bottom: 2px solid rgb(126, 126, 126);
  padding: 5px;
}
.invoice--event--name {
  width: 60%;
}
.invoice--event--type {
  width: 10%;
}
.invoice--event--quantity {
  width: 15%;
}
.invoice--event--price {
  width: 15%;
}
.invoice--event--details-container {
  border-bottom: 2px solid rgb(241, 241, 241);
  padding: 5px;
}
.invoice--event--pricing {
  width: 40%;
}
.invoice--event--final--price {
  border-top: 2px solid #1a87a3;
  border-bottom: 2px solid #1a87a3;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 40%;
}
.invoice--event--subtotal {
  width: 63%;
}
.invoice--event--totalAmount {
  width: 37%;
}
