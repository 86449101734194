.my--ticket--container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .my--ticket--box {
    width: 20em;
    height: 35em;
    background-color: white;
    border-radius: 5px;
    margin-right: 5px;

    .my--ticket--box--img {
      width: 20em;
      height: 15em;
      object-fit: contain;
      display: flex;
      justify-content: center;
      img {
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .my--ticket--event--details--ticket {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      margin-top: 0.3;
    }

    .my--ticket--event--date {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      margin-top: 0.5em;
    }
    .my--ticket--event--date {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      margin-top: 0.5em;
    }
    .my--ticket--attendee--details {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      margin-top: 0.2em;
      color: black;
    }
    .my--ticket--attendee--ticket-type {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 800;
      margin-top: 0.2em;
      color: #1a87a3;
    }
    .my--ticket--view--invoice {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 800;
      margin-top: 0.2em;
      color: #1d0dff;
      text-decoration: underline;
    }
  }
}
