.form-container {
  height: 80vh;
//   background-color: brown;

  display: flex;
  justify-content: center;
  padding-top: 16px;

  .form {
    background-color: #fefefe;
    filter: drop-shadow(0 0 0.4rem rgba(0,0,0,.382));
    padding: 40px 28px;
    border-radius: 14px;
    height: max-content;

    .PpTou {
      margin: 16px auto;
    }

    .password-description {
      font-size: 12px;
      margin-bottom: -4px;
    }

    .btn-continue {
      border: none;
      border-radius: 8px;
      height: 56px;
      font-size: 16px !important;
      font-weight: 600;
      display: block;
      width: 100%;
      background-color: #1c213a;
      color: #fff;
      letter-spacing: 0.8px;
    }
  }
}
