.analytics-container {
  // background-color: #fff !important;
  background-color: rgb(238, 238, 238);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: #232633;
  // height: 100vh;
  -webkit-box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.75);
}
