.hexagonIllustrationContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  .hexagonIllustrationContainerImg {
    width: 40em;
    height: 20em;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}
.hexagonBoxSponsors {
  position: relative;
  width: 9em;
  height: 9em;
  overflow: hidden;
  clip-path: polygon(74.9% 0, 100% 50%, 74.9% 100%, 25.1% 100%, 0 50%, 25.1% 0);
  background-color: rgb(29, 86, 172);
  display: flex;
  justify-content: center;
  align-items: center;

  .hexagonImgContainer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(233, 233, 233);
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }

  .hexagonImgContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.hexagonBoxSponsors-sidebar {
  position: relative;
  width: 9em;
  height: 9em;
  overflow: hidden;
  border-radius: 10px;
  // clip-path: polygon(74.9% 0, 100% 50%, 74.9% 100%, 25.1% 100%, 0 50%, 25.1% 0);
  background-color: rgb(29, 86, 172);
  display: flex;
  justify-content: center;
  align-items: center;

  .hexagonImgContainer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(233, 233, 233);
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }

  .hexagonImgContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
