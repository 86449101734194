.reception-content {
  max-width: 70%;
  margin: 0.1px auto;

  .community {
    transform: translateY(0px) translateZ(0px);

    &-banner {
      border-radius: 16px;
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      position: relative;
      z-index: 4;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-color: rgb(106, 77, 255);
      // background-image: url(https://d2xqcdy5rl17k2.cloudfront.net/images/summit/reception_bg.png);
      margin: 20px 0px !important;
    }

    &-content {
      display: flex;
      top: 0px;
      left: 0px;
      position: relative;
      height: 274px;
      width: 100%;
      flex-direction: column;
      padding: 66px 24px 16px;
      box-sizing: border-box;
      color: #fff !important;
      -webkit-font-smoothing: antialiased;
      opacity: 0.7;
      background: linear-gradient(rgb(19, 18, 22) 0%, rgba(19, 18, 22, 0) 100%);

      &__wrapper {
        width: 100%;
        height: 100%;

        // display: flex;
        // top: 0px;
        // left: 0px;
        // position: relative;
        // height: 274px;
        // width: 100%;
        // flex-direction: column;
        // padding: 66px 24px 16px;
        // box-sizing: border-box;

        h6 {
          margin-bottom: 8px;
          overflow-wrap: break-word;
          color: rgb(252, 252, 252);
          max-width: 560px;
          position: relative;
          line-height: 32px;
          font-size: 22px;
        }
        p {
          margin-bottom: 8px;
          opacity: 0.5;
          line-height: 20px;
          font-size: 15px;
          overflow-wrap: break-word;
          color: rgb(252, 252, 252);
          margin: 0;
          padding: 0;
          font-weight: 400;
        }
      }
    }
  }

  .event-details {
    display: flex;
    margin-bottom: 64px;
    padding-bottom: 64px;
    border-bottom: 0.4px dashed #d2d2d2;

    &__welcome {
      width: 68%;
      padding-right: 36px;
      text-align: justify;
      border-right-color: 2px solid #fff;
    }

    &__upcoming {
      width: 32%;

      &__sessions {
        width: 100%;
      }
    }
  }

  .event-sponsors__container {
    display: flex;
  }
  .event-sponsors {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    // width: 54%;
    margin: 80px 240px;
    text-align: left;
    padding: 32px;

    h3 {
      color: inherit;
      font-weight: 600;
    }

    &__container {
      margin: 40px 0;

      h5 {
        font-weight: 600;
        color: #fff;
      }
    }

    &__item {
      &:not(:last-child) {
        margin-right: 16px;
      }

      img {
        border: 1px solid #faa200;
        border-radius: 50%;
        margin-top: 5px;
        width: 100px;
        height: 100px;
      }
    }
  }
  // .our-sponsors {
  //   margin-top: 40px;

  //   &--heading {
  //     margin: 32px auto 40px;
  //     text-align: center;
  //     color: #fff;
  //     text-transform: uppercase;
  //     font-weight: 700;
  //   }

  //   &__group {
  //     text-align: center;
  //     margin: 24px auto 40px;

  //     &--heading {
  //       color: #fff;
  //       font-weight: 400;
  //     }

  //     &__list {
  //       display: flex;
  //       gap: 16px;
  //       justify-content: center;
  //       align-items: center;

  //       &--item {
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         flex-direction: column;
  //         width: 33.33%;
  //         height: max-content;
  //       }
  //     }
  //   }
  // }
}
