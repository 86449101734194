.attendee-analistics {
  //   background-color: aqua !important;
  width: 100%;
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 15px;
  background: linear-gradient(
    90deg,
    rgb(82, 186, 214) 0%,
    rgba(39, 169, 201, 1) 35%,
    rgba(26, 135, 163, 1) 100%
  );
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.attendee-analistics-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: 70px;
  height: 70px;
  margin-right: 10px;
  background: linear-gradient(
    90deg,
    rgb(82, 186, 214) 0%,
    rgba(39, 169, 201, 1) 35%,
    rgba(26, 135, 163, 1) 100%
  );
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.registered-user-card {
  //   background-color: aqua !important;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 15px;
  background-color: white !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.Tickets-analystic-card {
  width: 100%;
  height: 330px;
  border-radius: 15px;
  background-color: white !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}
.Tickets-analystic-card-content {
  margin-left: 15px;
}

.analystic-table-card {
  //   background-color: aqua !important;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid rgb(180, 180, 180);
  margin-bottom: 10em;
  background-color: white !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
}

.charts-analystic-card {
  width: 100%;
  height: 330px;
  border-radius: 15px;
  background-color: white !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}
