// .allbooths___main {
//     margin-top: 8%;
//     width: 100dvw;
//     height: 100dvh;
// }
.allBooths {
  display: flex;
  flex-wrap: wrap;
  .allBooths-box {
    width: 35em;
    height: 34em;
    border-radius: 10px;
    margin: 0px 10px 10px 10px;
    border: 1px solid #5e5e5e;
    padding: 10px;
    .allBooths-box-img {
      width: 100%;
      height: 28em;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .allBooths-box-text-container {
      width: 100%;
      display: flex;
      .allBooths-box-company-logo {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .allBooths-box-text-title {
        font-size: 14px;
        color: white;
        font-weight: 600;
      }
      .allBooths-box-text-tag {
        background-color: rgb(0, 0, 0);
        font-size: 9px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        padding: 3px 7px 3px 7px;
      }
    }
  }
}
.allBooth--companyNames {
  background-color: #6a7279;
  width: 90%;
  border-radius: 5px;
  padding: 7px;
  .allBooth--companyNames-text {
    color: white;
  }
}
