.community---container {
  //   height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
}

.chat---general---container {
  margin-top: 50px;
  width: 68% !important;
}

#chat3 .form-control {
  border-color: transparent;
}

#chat3 .form-control:focus {
  border-color: transparent;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.badge-dot {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-left: 2.9rem;
  margin-top: -0.75rem;
}
