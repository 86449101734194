.booth-lobby--booth {
  width: 100dvw;
  height: 100dvh;
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .booth-lobby--sponsor1 {
    position: absolute;
    width: 5.9%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;

    clip-path: polygon(
      74.9% 0,
      100% 50%,
      74.9% 100%,
      25.1% 100%,
      0 50%,
      25.1% 0
    );

    top: 13.6%;
    left: 29.9%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }
  .booth-lobby--sponsor2 {
    position: absolute;
    width: 6.6%;
    height: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;

    clip-path: polygon(
      74.9% 0,
      100% 50%,
      74.9% 100%,
      25.1% 100%,
      0 50%,
      25.1% 0
    );

    top: 22.5%;
    left: 37.3%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }

  .booth-lobby--sponsor3 {
    position: absolute;
    width: 8.5%;
    height: 15.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
    overflow: hidden;
    clip-path: polygon(
      74.9% 0,
      100% 50%,
      74.9% 100%,
      25.1% 100%,
      0 50%,
      25.1% 0
    );

    top: 9%;
    left: 45.7%;
    img {
      position: absolute;
    }
  }
  .booth-lobby--sponsor4 {
    position: absolute;
    width: 6.6%;
    height: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;

    clip-path: polygon(
      74.9% 0,
      100% 50%,
      74.9% 100%,
      25.1% 100%,
      0 50%,
      25.1% 0
    );

    top: 22.5%;
    left: 56%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }
  .booth-lobby--sponsor5 {
    position: absolute;
    width: 5.9%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;

    clip-path: polygon(
      74.9% 0,
      100% 50%,
      74.9% 100%,
      25.1% 100%,
      0 50%,
      25.1% 0
    );

    top: 13.6%;
    left: 64%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }
  .booth-lobby--reception-video {
    position: absolute;
    top: 38.4%;
    left: 43.5%;
    width: 13%;
    height: 8%;
    background-color: #1a87a3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;

    // animation: wave 2s infinite ease-out;
  }

  .booth-lobby--sideBar1 {
    position: absolute;
    width: 8.9%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
    // border: 2px solid black;

    // clip-path: polygon(
    //   74.9% 0,
    //   100% 50%,
    //   74.9% 100%,
    //   25.1% 100%,
    //   0 50%,
    //   25.1% 0
    // );

    top: 20.6%;
    left: 1.5%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }
  .booth-lobby--sideBar4 {
    position: absolute;
    width: 8.9%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
    // border: 2px solid black;
    top: 20.6%;
    right: 1.8%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }
  .booth-lobby--sideBar2 {
    position: absolute;
    width: 8.5%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(1.5deg);
    // background-color: red;
    // border: 2px solid black;
    top: 23.6%;
    left: 20.8%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }
  .booth-lobby--sideBar3 {
    position: absolute;
    width: 8.9%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-1deg);
    // background-color: red;
    // border: 2px solid black;
    top: 24.6%;
    right: 20.8%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }
  .booth-lobby--sideBar4 {
    position: absolute;
    width: 8.9%;
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
    // border: 2px solid black;
    top: 20.6%;
    right: 1.8%;
    overflow: hidden;
    img {
      position: absolute;
    }
  }
}
