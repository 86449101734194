/* Change the arrow color */
// .slick-prev:before,
// .slick-next:before {
//   color: rgb(0, 255, 128); /* Arrow color */
// }

// .slick-prev,
// .slick-next {
//   //   background: purple !important;
//   border-radius: 50%;
//   color: white !important;
// }
// .slider-container {
//   width: 100% !important;
// }
.slick-list {
  //   background-color: aqua !important;
  height: 450px !important;
}
.slick-slide {
  opacity: 1 !important; /* Force full opacity */
  //   visibility: visible !important; /* Ensure visibility */
  height: 400px !important; /* Adjust based on your content */
  width: 295px !important; /* Ensure this matches your design */
  display: inline-block;
  //   background-color: yellow !important;
}
.slick-track {
  display: flex;
  align-items: center; /* Adjust as needed */
}
.slider-container {
  display: flex;
  height: 500px !important;
}

.event-type {
  cursor: pointer;
}

.glossary {
  max-width: 78%;

  &__term-and-definition {
    // display: flex;
    vertical-align: top;

    h6 {
      margin-right: 12px;
      font-size: 16px;
    }

    td {
      font-size: 13px;
    }
  }
}
