.tab--booth-body-container {
  background-color: #fff !important;
  border-radius: 3px;
  .btn--text {
    color: white;
  }
}
.preview-booth-container {
  position: relative;
  width: 100%;
  height: 400px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  //   .preview-booth-option {
  // position: absolute;
  // width: 17%;
  // height: 17%;
  // top: 37%;
  // left: 13%;
  // z-index: 99;
  //   }
}
