.ticketing-card-main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: white !important;

  .ticketing-card-container {
    background-color: white;
    width: 252px;
    .ticketing-card-divider {
      position: relative;
      top: 40%;
      bottom: 50%;
      left: 0px;
      right: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .ticketing-card-divider-left {
        position: absolute;
        width: 28px;
        height: 28px;
        border-left: 2px solid rgba(128, 128, 128, 0.2);
        left: 235px;
        border-radius: 50%;
        z-index: 99;
        background-color: rgb(255, 255, 255) !important;
      }
      .ticketing-card-divider-right {
        position: absolute;
        width: 28px;
        height: 28px;
        border-right: 2px solid rgba(128, 128, 128, 0.2);
        border-radius: 50%;
        left: -14px;
        background-color: white !important;
        z-index: 99;
      }
    }
  }
  .ticketing-card {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    width: 252px;
    height: 430px;
    border: 1px solid rgba(128, 128, 128, 0.2);
    background-color: white !important;
    overflow: hidden;

    .ticketing-card-top {
      position: absolute;
      top: 0px;
      right: 0px;
      left: 0px;
      padding: 15px;
      .ticketing-card-top-title {
        width: 220px;
        display: flex;
        justify-content: space-between;
        .ticketing-card-top-title-type {
          font-size: 13px;
          font-weight: bolder;
        }
        .ticketing-card-top-title-price {
          font-size: 24px;
          font-weight: bolder;
        }
        .ticketing-title-percentage {
          font-size: 29px;
          font-weight: bolder;
        }
        .ticketing-title-Sold {
          color: grey;
        }
        .ticketing-card-top-title-progress {
          width: 153px;
          padding-top: 19px;
          .ticket-progress-bar {
            height: 10px;
          }
          .ticket-progress-bar-vip {
            height: 10px;
          }
        }
      }
      .ticketing-status-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        //   align-items: flex-end;
        //   right: 0px;
      }
      .ticketing-card-top-status-status {
        background-color: rgb(233, 233, 233);
        border: none;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
      }
      .ticketing-card-days {
        font-size: 11px;
        font-weight: 500;
        color: rgb(160, 160, 160);
      }
    }

    .ticketing-card-bottom {
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;

      .ticketing-card-bottom-container {
        position: absolute;
        height: 200px;
        top: -230px;
        width: 252px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        //
        .ticketing-card-bottom-first {
          padding-left: 15px;
          border-right: 2px dashed rgb(228, 228, 228);
        }
        .ticketing-card-bottom-second {
          padding-left: 15px;
        }
        .ticketing-bottom-title {
          font-size: 13px;
          font-weight: bold;
          color: rgb(155, 154, 154) !important;
        }
        .ticketing-bottom-value {
          font-size: 18px;
          font-weight: bolder;
          color: rgb(0, 0, 0) !important;
        }
      }
      .ticketing-card-bottom-circle-main {
        position: absolute;
        bottom: -245px;
        border: 15px solid rgb(248, 248, 248);
        width: 310px;
        height: 310px;
        border-radius: 50%;
        border-top-right-radius: 70px * 2;
      }
    }
  }
}
