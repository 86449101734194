.single-attendee__body {
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
  overflow-y: auto;

  &--form {
    &-input-container {
      margin-right: 16px;

      &-field {
        width: 100%;
        outline: none;
        padding: 10px 15px;
        font-style: normal;
        font-size: 16px;
        line-height: 1.2;
        height: 60px;
        border-radius: 8px;
        transition: all 0.2s ease-in-out 0s;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        appearance: none;
        background: transparent;
        border: 1px solid rgb(154, 151, 170);

        &:hover {
          border: 1px solid #45cb85;
        }

        // &:active {
        //   box-shadow: 0 0 10px #007bff;
        //   box-shadow: 0 0 10px #007bff;
        // }
      }

      &-label {
        position: absolute;
        left: 0px;
        text-align: left;
        transition: all 0.2s ease-in-out 0s;
        font-weight: normal;
        font-size: 16px;
        cursor: text;
        top: 8px;
        bottom: 0px;
        padding: 8px 15px;
        margin-bottom: 13px;
        width: 100%;
        word-break: break-word;
        z-index: 1;
        pointer-events: none;
        color: rgb(102, 99, 121);
      }

      & > div > div:nth-child(3) {
        clear: left;
      }
    }
  }
}

.field-col-half {
  width: calc(50% - 8px);
  float: left;

  &:first-child {
    margin-right: 16px;
  }
}

.gzIdbH {
  margin-bottom: 16px;
  position: relative;
  border: 1px solid transparent;
  border-radius: 8px;
  background: transparent;
}

input {
  overflow: visible;
}

label {
  display: inline-block;
}
