.buy--ticket-container {
  width: 65%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
}

.buy--ticket--quatity--card {
  position: relative;
  display: flex;
  justify-content: center;
  width: 250px;
  height: 190px;
  padding: 5px;
  background-color: white;
  border: 1px solid rgb(141, 141, 141);
  border-radius: 10px;
  margin: 0px 10px 0px 10px;
}
.buy--ticket--quatity--details {
  margin-top: 10px;
}
.buy--ticket--quatity--btn {
  position: absolute;
  width: 100%;
  bottom: 5px;
  right: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  //   width: 60px;
}
