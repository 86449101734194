.bulk-attendee__heading {
  &--header {
    margin-bottom: 8px;
    line-height: 20px;
    font-size: 15px;
    color: rgb(7, 7, 8);
  }

  &--description {
    margin-bottom: 24px;
    line-height: 16px;
    font-size: 12px;
    color: rgb(93, 90, 109);
    font-weight: normal;
  }
}

.bulk-attendee__body {
  position: relative;
  height: 192px;
  max-width: 387px;
  background: rgb(209, 207, 216);
  //   border: 1px dashed rgba(93, 90, 109, 0.16);
  border: 1px dashed #45cb85;
  border-radius: 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;

  &--header {
    margin-top: 16px;
    line-height: 16px;
    font-size: 12px;
    overflow-wrap: break-word;
    color: rgb(93, 90, 109);
  }

  &--description {
    margin-top: 8px;
    line-height: 20px;
    font-size: 12px;
    overflow-wrap: break-word;
    color: rgb(7, 7, 8);
    font-weight: normal;
  }

  input[type="file"] {
    position: absolute;
    inset: 0px;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }
}

.next-container {
  box-sizing: border-box;
  margin-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 5px;
  display: flex;

  button {
    margin: 28px 0 0 -30px;
    width: 100%;
    color: rgb(7, 7, 8);
    -webkit-box-align: center;
    align-items: center;
    padding: 14px 5px;
    font-style: normal;
    font-size: 16px;
    line-height: 125%;
    height: 50px;
    background: #45cb85;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: all 0.3s ease 0s;
    max-width: 120px;
    cursor: pointer;
    position: relative;

    &:disabled {
      background-color: rgb(220, 219, 225);
      cursor: not-allowed;
      opacity: 1;
    }

    p {
      line-height: 20px;
      font-size: 15px;
      overflow-wrap: break-word;
      font-weight: 700;
    }
  }
}

// file upload style
//dropzone styling

// input {
//   overflow: visible;
// }
.drop-zone {
  position: relative;
  border: 2px dashed #c0c0c0;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 4%;
}

/* Styles for the drop zone label */
.drop-zone-label {
  display: block;
  margin-bottom: 10px;
}

/* Styles for the icon inside the drop zone label */
.drop-zone-label i {
  font-size: 24px;
  margin-bottom: 5px;
}

/* Styles for the text inside the drop zone label */
.drop-zone-label .drop-zone-text {
  font-size: 16px;
  font-weight: bold;
}

/* Hide the default file input */
.drop-zone-input {
  // display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* Preview file css */

.file-previews-doc {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.file-preview-doc-img {
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.file-preview-doc {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.file-icon-doc {
  font-size: 28px;
  margin-right: 15px;
  // color: #1a87a3;
  color: #45cb85 !important;
}

.file-details-doc {
  display: flex;
  flex-direction: column;
}

.file-name-doc {
  font-size: 16px;
  font-weight: bold;
}

.file-size-doc {
  font-size: 14px;
  color: #666;
}

.btn-width {
  width: 120px !important;
}
.bookableImages-sizes {
  width: 200px !important;
  height: 200px !important;
}

/* Media query for responsiveness */
@media (max-width: 576px) {
  .file-preview-doc {
    width: 100%;
  }
}
