.ticket-card-main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .ticket-card-container {
    background-color: white;
    width: 252px;
    .ticket-card-divider {
      position: relative;
      top: 40%;
      bottom: 50%;
      left: 0px;
      right: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .ticket-card-divider-left {
        position: absolute;
        width: 28px;
        height: 28px;
        border-left: 2px solid rgba(128, 128, 128, 0.2);
        left: 235px;
        border-radius: 50%;
        z-index: 99;
        background-color: rgb(255, 255, 255) !important;
      }
      .ticket-card-divider-right {
        position: absolute;
        width: 28px;
        height: 28px;
        border-right: 2px solid rgba(128, 128, 128, 0.2);
        border-radius: 50%;
        left: -14px;
        background-color: white !important;
        z-index: 99;
      }
    }
  }
  .ticket-card {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    width: 252px;
    height: 330px;
    border: 1px solid rgba(128, 128, 128, 0.2);
    background-color: white !important;
    overflow: hidden;

    .ticket-card-top {
      position: absolute;
      top: 0px;
      right: 0px;
      left: 0px;
      padding: 15px;
      .ticket-card-top-title {
        width: 220px;
        display: flex;
        .ticket-card-top-title-type {
          font-size: 22px;
          font-weight: bolder;
        }

        .ticket-title-price {
          text-align: center;
          font-size: 28px;
          font-weight: bolder;
        }
        .ticket-discount-price {
          font-size: medium;
          font-weight: 600;
        }
      }

      .ticket-card-top-status-status {
        background-color: rgb(233, 233, 233);
        border: none;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
      }
      .ticket-card-days {
        font-size: 11px;
        font-weight: 500;
        color: rgb(160, 160, 160);
      }
    }
    .ticket-horizontal-diver-main {
      width: 252px;
      position: absolute;
      top: 148px;
      border-bottom: 3px dashed rgb(228, 228, 228);
    }
    .ticket-card-bottom {
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;

      .ticket-card-bottom-container {
        position: absolute;
        height: 200px;
        top: -150px;
        width: 252px;
        display: flex;
        justify-content: center;
      }
      .ticket-card-bottom-circle-main {
        position: absolute;
        bottom: -245px;
        border: 15px solid rgb(248, 248, 248);
        width: 310px;
        height: 310px;
        border-radius: 50%;
        border-top-right-radius: 70px * 2;
      }
    }
  }
}
