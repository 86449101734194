.reception-header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 56px;
  min-width: 80px;
  z-index: 990;
  position: fixed;
  // top: 0px;
  // left: 0px;
  margin-top: -10px;
  right: 0px;
  width: 100%;
  transition: background-color 0.3s ease-in-out 0s;
  background-color: #060507;
  backdrop-filter: blur(70px);
  padding-right: 24px;
}

.reception-navbar {
  flex: 1 1 0%;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: normal;
  margin-left: 0px;
  min-width: 0px;
}

.reception-navbar__menu {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  flex: 1 1 0%;
  min-width: 0px;
  gap: 32px;
  margin-left: 72px;
  margin-right: 100px;
  margin-bottom: 0px;
  padding-left: 0px;

  &--item {
    height: 56px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
    color: rgba(241, 241, 241, 0.6);
    font-size: 16px;
  }

  &--link {
    overflow-wrap: break-word;
    color: inherit;
    font-size: 0.875rem;
    line-height: 1.25rem;

    &--active {
      //   border-bottom: 4px solid #3b5de7;
    }
  }
}

.reception-header__extended {
  min-width: auto;
  display: flex;
  // background-color: #2bc66d;
  -webkit-box-pack: end;
  justify-content: end;

  &--icon {
    color: rgba(241, 241, 241, 0.6);
    margin: auto 12px;
    cursor: pointer;
    // position: relative;

    // &--tooltip {
    //   visibility: hidden;
    //   width: 120px;
    //   background-color: black;
    //   color: #fff;
    //   text-align: center;
    //   border-radius: 6px;
    //   padding: 5px 0;

    //   /* Position the tooltip */
    //   position: absolute;
    //   z-index: 1;

    //   &:hover {
    //     visibility: visible;
    //   }
    // }
  }
  &--profile {
    display: flex;
    align-items: center;
    background-color: #2dd173;
    padding: 4px;
    border-radius: 4px;

    span {
      color: #fefefe;
      font-size: 10px;
    }
  }
}

.tooltiping {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  cursor: pointer;

  &__content {
    position: absolute;
    top: 180%;
    right: 10%;
    // transform: translateX(-70%);

    background-color: #2b2a2e;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 8px;
    width: 250px;
    filter: drop-shadow(0 0 2rem rgba(0, 0, 0, 0.582));

    display: flex;
    flex-direction: column;
    gap: 8px;

    &--attendee {
      display: flex;
      align-items: center;
      gap: 14px;

      background-color: #060507;
      padding: 8px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 8px;
      margin: 1px auto;

      &--img {
        width: 15%;
        height: 15%;
        border-radius: 50%;
      }

      &--info {
        // width: 100%;

        &-name {
          font-size: 12px;
          font-weight: 700;
          margin: 0;
        }
        &-status {
          font-size: 12px;
          margin-top: -2px;

          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }

    &--message {
      position: relative;
      display: flex;
      align-items: center;
      gap: 14px;

      background-color: #060507;
      padding: 8px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 8px;
      margin: 1px auto;

      &--img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      &--activity-track {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 18%;
        left: 15%;
        z-index: 1800;
        background-color: #2dd173;
        border-radius: 50%;
      }

      &--info {
        // width: 100%;

        &-name {
          font-size: 12px;
          font-weight: 700;
          margin: 0;
        }
        &-status {
          font-size: 12px;
          margin-top: -4px;
        }
      }
    }

    &--alerts {
      position: relative;
      display: flex;
      align-items: center;
      gap: 14px;
      width: 100%;

      background-color: #060507;
      padding: 8px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 8px;
      margin: 1px auto;

      &--img {
        width: 100%;
        height: 100%;
        color: #2bc66d;
      }

      &--info {
        // width: 100%;

        &-name {
          font-size: 12px;
          font-weight: 700;
          margin: 0;
        }
        &-status {
          font-size: 12px;
          margin-top: -4px;
        }
      }
    }

    &--show-all {
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.tooltiping .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltiping:hover .tooltiptext {
  visibility: visible;
}
