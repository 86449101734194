.sub-title {
  color: rgba(35, 38, 51, 0.7);
  font-size: 13px;
}

.summary-container {
  background-color: rgba(73, 80, 87, 0.15);
  color: #232633;
  width: 100%;
  height: 100%;
  padding: 24px 80px;

  display: flex;
  gap: 8rem;
}

.summary-setup {
  //   background-color: rgba(73, 80, 87, .15);
  width: 60%;
  height: 100%;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    // background-color: rgba(73, 80, 87, .15);

    table {
      border-collapse: separate;
      border-spacing: 0;
      width: 100%;
      border: 1px solid rgba(73, 80, 87, 0.4);
      border-radius: 6px;
      overflow: hidden;
      background-color: #ffffff;
      margin-top: 20px;
      padding: 12px;
    }
    th,
    td {
      padding: 10px;
      text-align: left;
      white-space: nowrap;
    }
    th {
      background-color: #ffffff;
    }
    td {
      //   border-left: 1px solid #232633;
    }
  }

  &__affiliates {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;

    &--sponsors {
      background-color: #ffffff;
      margin: 14px 0;
      border: 1px solid rgba(73, 80, 87, 0.4);
      border-radius: 6px;
      padding: 12px 12px 14px;
      width: 100%;

      &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__body {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .btn-add-speaker {
          width: 70px;
          width: max-content;
          padding: 8px 70px;
          border: 1px dashed #232633;
          border-radius: 4px;
        }
      }
    }

    &--booths {
      background-color: #ffffff;
      margin: 14px 0;
      border: 1px solid rgba(73, 80, 87, 0.4);
      border-radius: 6px;
      padding: 12px 12px 14px;

      &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__body {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .btn-add-speaker {
          width: 49%;
          width: max-content;
          padding: 8px 70px;
          border: 1px dashed #232633;
          border-radius: 4px;
        }
      }
    }
  }
}

.summary-details {
  //   background-color: #f3f5f7;
  width: 40%;
  height: 100%;

  &__content {
    background-color: #ffffff;
    border: 1px solid rgba(73, 80, 87, 0.4);
    border-radius: 8px;
    padding: 16px 18px 12px;
    margin-top: 20px;

    &--heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  &__settings {
    margin: 16px auto;

    &--body {
      color: rgba(35, 38, 51, 0.7);
      margin-top: 8px;

      li {
        margin: 6px;
      }
    }
  }
}

//overide sponsors style
.summary-setup__affiliates {
  width: "100%" !important;
}

.speakers-space {
  // background-color: purple;
  width: min-content;
  border-left: 1px solid #232633;

  .avatar {
    width: 40%;
    border: 1px solid #232633;
    border-radius: 50%;
    margin-left: 6px;
  }
}

/* CSS for sliding in from the right */
.modal.modal-right {
  transform: translateX(100%);
}

.modal.modal-right.show {
  transform: translateX(0);
}

.modal.modal-right .modal-dialog {
  max-width: 100%;
  margin: 0;
  width: 40%;
  transition: transform 0.3s ease-in-out;
}
