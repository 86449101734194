.login {
  //   background-color: blueviolet;
  height: 100vh;
  width: 100vw;

  display: flex;
}

.login__form {
  //   background-color: brown;
  height: 100%;
  flex: 0.5;

  &__container {
    // background-color: bisque;
    height: 100%;
    width: 60%;
    padding: 40px 0;
    margin: 0 auto;

    &--header {
      margin: 32px 0 64px;
    }

    &--body {
      &__heading {
        margin-bottom: 40px !important;

        h1 {
          color: #1c213a;
          font-weight: 600;
          font-size: 32px;
          line-height: 41px;
          padding-top: 24px;
          margin-bottom: 24px;
        }
        p {
          color: rgb(98, 100, 115);
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
      }

      .form-input-group {
        margin-bottom: 16px !important;

        &--label {
          display: block;
          color: #4b4d5f;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 5px;
        }

        &__input-box {
          cursor: pointer;
          position: relative;
          display: inline-flex;
          width: 100%;

          &--field {
            color: rgb(51, 51, 51);
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            padding-left: auto 16px;
          }
        }
      }

      .input {
        display: block;
        width: 100%;
        padding: 12px 16px;
        line-height: 22px;
        background-color: transparent !important;
        background-image: none;
        border-radius: 8px !important;
        background-clip: padding-box;
        border: 1px solid #dcdddc;
        box-shadow: none;
        outline: 0;
      }

      .forgot-password-container {
        margin: 24px 0 40px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .btn-login {
        border: none;
        border-radius: 16px;
        height: 56px;
        font-size: 16px !important;
        font-weight: 600;
        display: block;
        width: 100%;
        background-color: #232633;
        color: #fff;
        letter-spacing: 0.8px;
      }
    }
  }
}

.login__slider {
  background-color: #232633;
  height: 100%;
  flex: 0.5;
  padding-top: 56px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  position: relative;

  .btn-signup {
    border: none;
    border-radius: 16px;
    height: 52px;
    font-size: 16px !important;
    font-weight: 600;
    display: block;
    width: 20%;
    background-color: #fff;
    color: #232633;
    letter-spacing: 0.8px;
  }

  .login-page-slider {
    position: absolute;
    top: 24%;
    // transform: translateY(-54%);
    right: 0;
    text-align: center;

    &--image {
      width: 72%;
    }
    &__description {
      color: #fff;

      h3 {
        color: #fff;
        font-size: 2.5vh;
        font-weight: 600;
        margin: 1.5vh auto;
      }
      p {
        text-align: center;
        font-weight: 500;
        font-size: 1.5vh;
        color: #c3d1f8;
        margin-bottom: 1px;
      }
    }
  }
}
