.booths-content {
  max-width: 70%;
  margin: 0.1px auto;
}

.booths-content__body {
  margin: 32px 24px;

  &--booth {
    input:focus {
      border: none;
      outline: none;
      color: #fefefe;
    }
  }
}
