.session---landing---card {
  width: 100%;
  // background-color: rgb(206, 33, 33);
  background-color: white;
  border-radius: 10px;
  .vertical---landing---line {
    height: "95%";
    width: 7px;
    background-color: rgb(63, 63, 63);
    border-radius: 10px;
    margin-right: 10px;
  }
  .session---landing---time {
    font-weight: 600;
    color: rgb(63, 63, 63);

    i {
      margin-right: 8px;
      margin-top: 2px;
      font-size: 14px;
      // background-color: rgb(63, 63, 63);
      // color: rgb(63, 63, 63);
    }
  }
  .session---landing---title {
    p {
      font-size: 20px;
      font-weight: 900;
    }
  }
}
