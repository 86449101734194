.bg-img {
  // background-image: url(../../../../assets/images/low-poly-grid-haikei.png);\
  // background-color: #faa200;
}

.event-landing-navbar {
  width: 100%;
  height: 64px;
  background-color: #000;
  border-bottom: 1px solid #87849a;
  color: #fff !important;
  // position: fixed;
  // top: 0;
  // right: 0;
  // left: 0;
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.event-landing-navbar__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-top: 12px;
  color: #fff !important;

  &--item {
    list-style-type: none;
    font-size: 14px;

    &-link {
      color: #87849a;
      font-size: 18px;

      &:hover {
        color: #297ea6;
        border-bottom: 1px solid #297ea6;
      }
    }
  }
}

.event-landing-container {
  height: max-content;
  color: #ffffff;

  .event-landing-btn {
    padding: 16px 140px;
    background-color: #1a87a3;
    border: none;
    margin: 16px auto 12px;
  }
  .event-landing-banner-container {
    width: 100%;
    height: 35.5em;
    display: flex;
    justify-content: center;
    .event-landing-banner-wrapper {
      width: 68%;
      height: 35.5em;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }
  }

  .overview {
    color: #000;
    font-weight: 600;
    font-size: 16px;

    // width: 54%;
    margin: 80px 240px;
    text-align: left;
    // padding: 32px;

    h3 {
      color: inherit;
      font-weight: 600;
    }
  }

  .schedule {
    // color: #ffffff;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    margin: 80px 240px;
    text-align: left;
    padding: 32px;

    h3 {
      color: inherit;
      font-weight: 600;
    }

    &__date {
      margin: 40px 0;
      padding: 12px 24px;
      border-radius: 12px;
      width: max-content;
      // width: 120px;

      h4 {
        margin-bottom: 0;
        // color: white;
        color: #000;

        span {
          font-size: 12px;
        }
      }
    }

    &__items {
      margin: 48px 0;

      &__item {
        margin: 24px 0;

        &--time {
          font-size: 18px;
          letter-spacing: 1px;
        }
        &--name {
          margin-left: 24%;

          &:hover {
            background-color: #1d1c22;
            border: 1px solid #fefefe;

            border-radius: 12px;
            padding: 16px 24px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .hosts {
    // color: #ffffff;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    // width: 54%;
    margin: 20px 240px;
    text-align: left;
    padding: 16px 0px;

    h3 {
      color: inherit;
      font-weight: 600;
    }

    .host {
      margin: 20px 1px;
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;

      &--profile-img {
        font-size: 64px;
        // color: #fff;
        color: #000;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        width: 180px;
        height: 180px;
        border-radius: 90px;
      }

      &--name {
        font-weight: 600;
        margin: 12px 0;
      }

      &--profile-slider {
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .event-sponsors__container {
    display: flex;
  }
  .event-sponsors {
    // color: #ffffff;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    // width: 54%;
    margin: 20px 240px;
    text-align: left;
    padding: 16px 0;

    h3 {
      color: inherit;
      font-weight: 600;
    }

    &__container {
      margin: 40px 0;

      h5 {
        font-weight: 600;
        // color: #fff;
        color: #000;
      }
    }

    &__item {
      &:not(:last-child) {
        margin-right: 16px;
      }

      img {
        border-radius: 50%;
        margin-top: 16px;
        width: 100px;
        height: 100px;
      }
    }
  }

  .support {
    // color: #ffffff;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: -40px;
    padding: 32px;

    h3 {
      color: inherit;
      font-weight: 600;
    }

    p {
      color: #87849a;
    }
  }
}
