.sessions-content {
  background-color: rgba(19, 18, 22, 1);
  height: 100vh;
  max-width: 70%;
  margin: 0.1px auto;
}

.sessions-calender {
  max-height: 650px;
  overflow-y: auto;
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.08);
  border: 0.4px solid #939697;
  border-radius: 8px;

  &__header {
    // background-color: red;
    margin: 0 0 24px 30px;

    h2 {
      color: #fefefe;
    }
  }

  &__body {
    // background-color: brown;
    display: flex;

    &__dates {
      width: 15%;
      // background-color: orange;
      display: flex;
      flex-direction: column;
      align-items: center;

      .month {
        color: #939697;
        text-transform: uppercase;
        margin-bottom: 8px;
        font-size: 12px;

        &:not(:first-child) {
          margin-top: 16px;
        }
      }
      .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #2dd173;
        // padding: 4px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 16px;

        span {
          margin-bottom: -2px;
          color: #fefefe;

          &:not(:first-child) {
            font-size: 10px;
          }
        }
      }
    }

    &__events {
      width: 85%;
      // background-color: purple;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}
