.verify---container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.verify---card {
  background-color: white;
  width: 530px;
  height: 390px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .verify---title {
    font-weight: 900;
    color: rgb(112, 112, 112);
    text-align: center;
  }
  .verify---btn {
    Button {
      width: 120px;
      height: 35px;
      border: none;
      border-radius: 15px;
      background-color: #0ac074;
      font-size: 12;
      color: white;
      font-weight: 800;
    }
  }
}
